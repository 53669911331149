import Box from "Components/V2/Box";
import {Navigate} from "react-router-dom";
import {useAgreements} from "../../../Apis/AgreementApi";
import {useTranslation} from "react-i18next";
import {StyledText} from "../../../Components/V2/Text";
import Separator from "../../../Components/V2/Separator";
import FlexRow from "../../../Components/FlexRow";
import Gap from "Components/V2/Gap";
import Grid from "Components/V2/Grid";
import CircledGraphIcon from "../../../Assets/Icons/V2/CircledGraphIcon";
import CircledLeaf from "../../../Assets/Icons/V2/CircledLeaf";
import CircledMatchIcon from "../../../Assets/Icons/V2/CircledMatchIcon";
import LightningIcon from "../../../Assets/Icons/V2/LightningIcon";
import {useEnergiData} from "../../../Apis/EnergiDataApi";
import styles from './AgreementDashboardPage.module.scss';
import AgreementSelector from "../../../Features/Dashboard/Components/AgreementSelector";
import Tooltip from "../../../Components/V2/Tooltip";
import React, {useEffect, useRef, useState} from "react";
import {useDashboardStats} from "../../../Apis/UsageApi";
import useSelectedAgreement from "../../../Features/Dashboard/Hooks/useSelectedAgreement";
import {formatNumber} from "../../../Utils/NumberUtils";
import {Bar, BarChart, XAxis, YAxis} from "recharts";
import moment from "moment";
import CircledSolarPanel from "../../../Assets/Icons/V2/CircledSolarPanel";
import CalendlyBooker from "../../../Features/Onboarding/Components/CalendlyBooker";

const AgreementDashboardPage = () => {

    const { t } = useTranslation();

    const agreement = useSelectedAgreement();

    const chartsDivRef = useRef<HTMLDivElement>(null)

    const { data: agreements } = useAgreements(true);
    const { data: dashboardStats } = useDashboardStats(agreement?.id)
    const { data: energiData } = useEnergiData();

    const [monthIndex, setMonthIndex] = useState<number | undefined>(undefined)
    const [powerUsage, setPowerUsage] = useState<number>(0);
    const [powerCoverate, setCoverate] = useState<number>(0);
    const [c02Reduction, setC02Reduction] = useState<number>(0)

    const isMatch = !agreement ? (!!agreements && agreements?.filter(x => x.agreementType === 'Match' || x.agreementType === 'MatchAndCustom').length > 0) : (agreement.agreementType === 'MatchAndCustom' || agreement.agreementType === 'Match')
    const isCustomUsage = !agreement ? (!!agreements && agreements?.filter(x => x.agreementType === 'Custom' || x.agreementType === 'MatchAndCustom').length > 0) : (agreement.agreementType === 'Custom' || agreement.agreementType === 'MatchAndCustom')

    useEffect(() => {
        setMonthIndex(undefined)
    }, [agreement]);

    const animateValue = (setFunction, start, end, duration) => {
        if (start === end && end !== 0) {
            return
        }

        const range = end - start;
        let current = start;
        const increment = range / (duration / 5)

        let stepTime = 6

        const timer = setInterval(function() {
            current += increment;

            setFunction(current > end ? end : current);

            if (current >= end) {
                clearInterval(timer);
            }
        }, stepTime);
    }

    useEffect(() => {
        if (dashboardStats) {
            const TotalConsumption = !!monthIndex ? dashboardStats[monthIndex].powerConsumption : dashboardStats.reduce((acc, stat) => acc + stat.powerConsumption, 0)
            const TotalC02Reduction = !!monthIndex ? dashboardStats[monthIndex].cO2Reduction : dashboardStats.reduce((acc, stat) => acc + stat.cO2Reduction, 0)

            animateValue(setPowerUsage, 0, TotalConsumption / 1000, 200);
            animateValue(setCoverate, 0, 100, 400);
            animateValue(setC02Reduction, 0, TotalC02Reduction / 1000 / 1000, 600);
        }
    }, [dashboardStats, monthIndex]);

    if (!!agreements && agreements.length === 1 && agreements[0].agreementStatus === "Pending") {
        return <Navigate to={`/v2/dashboard/${agreements[0].id}/onboarding`}  />
    }

    const getChartData = () => {
        return dashboardStats?.map((x, index) => ({
            key: moment().month(index).format("MMM"),
            month: index,
            powerConsumption: x.powerConsumption / 1000,
            powerProduction: x.powerProduction / 1000
        })) ?? []
    }

    const renderStat = (props: { text: string, icon: React.ReactElement, value: number, unit: string}) => {
        if (isMatch) {
            return (
                <Grid gridTemplateColumns={"auto 1fr auto"} columnGap={8}>

                    <FlexRow direction={"column"} align={"start"} justify={"center"}>
                        {props.icon}
                    </FlexRow>

                    <FlexRow direction={"column"} align={"start"} justify={"center"}>
                        <StyledText type={"Caption"} weight={"Bold"}>{props.text}</StyledText>
                        <div>
                            <StyledText type={"Body"}>{formatNumber(props.value, 2)} {props.unit}</StyledText>
                        </div>
                    </FlexRow>
                </Grid>
            )
        } else {
            return (
                <FlexRow justify={"center"} style={{gap: 8}} direction={"column"}>
                    <FlexRow  justify={"center"} align={"start"}>
                        {props.icon}
                    </FlexRow>
                    <div>
                        <StyledText type={"Lead"} weight={"Bold"}>{formatNumber(props.value, 2)}</StyledText><StyledText type={"Small"}> {props.unit}</StyledText>
                    </div>
                    <StyledText style={{textAlign: 'center'}} type={"Small"}>{props.text}</StyledText>
                </FlexRow>
            )
        }
    }

    const getStats = () => {
        const stats: any[] = [];

        if (isMatch) {
            stats.push({index: 0, element: renderStat({text: t('agreement:dashboard.ownStat.1.text'), icon: <CircledGraphIcon />, value: powerUsage, unit: 'MWh'})})
            stats.push({index: 1, element: renderStat({text: t('agreement:dashboard.ownStat.3.text'), icon: <CircledMatchIcon />, value: powerCoverate, unit: '%'})})
        }

        if (isCustomUsage && !isMatch) {
            stats.push({index: 2, element: renderStat({text: t('agreement:dashboard.ownStat.2.text'), icon: <CircledSolarPanel width={60} height={60}  />, value: powerUsage, unit: 'MWh'})})
        }

        if (isMatch ||isCustomUsage) {
            stats.push({index: 3, element: renderStat({text: t('agreement:dashboard.ownStat.4.text'), icon: <CircledLeaf width={60} height={60} />, value: c02Reduction, unit: 'ton'})})
        }


        return stats;
    }
    
    return (
        <>
            <Box className={styles.container}>
                <FlexRow justify={"space-between"}>
                    <StyledText type={"Lead"} weight={"Bold"} color={"secondary"}>{t('dashboard')}</StyledText>
                    <Gap size={"medium"}>
                        <div>
                            <AgreementSelector onlySigned />
                        </div>

                        <div>
                            <StyledText type={"Caption"} weight={"Bold"}>{t('yearToDate')}</StyledText>
                        </div>
                    </Gap>
                </FlexRow>

                <Separator/>

                <Grid gridTemplateColumns={isMatch ? "minmax(500px, 1fr) minmax(500px, 1fr)" : "1fr"} style={{justifyContent: 'center'}} className={isMatch ? "" : styles.grid}>
                    {isMatch && (
                        <>
                            {getChartData().map(x => x.powerConsumption)?.reduce((acc, x) => acc + x, 0) > 0 ? (
                                <div ref={chartsDivRef}>
                                    <BarChart barGap={0} width={chartsDivRef.current?.clientWidth ?? 400} height={320} data={getChartData()}>
                                        <YAxis tickCount={5} orientation={"right"} axisLine={false} tickLine={false} />
                                        <XAxis axisLine={false} tickLine={false}  dataKey="key" style={{fontWeight: '700', fontFamily: 'Work Sans'}} />
                                        <Bar onMouseEnter={e => setMonthIndex(e.month)} onMouseLeave={_ => setMonthIndex(undefined)} isAnimationActive={true} animationDuration={1000} dataKey="powerConsumption" fill="#171717" radius={4} />
                                        <Bar onMouseEnter={e => setMonthIndex(e.month)} onMouseLeave={_ => setMonthIndex(undefined)} dataKey="powerConsumption" fill="#D1B6E2" radius={4} />
                                    </BarChart>
                                </div>
                            ) : (
                                <div className={"flex align-center justify-center"}>
                                    <StyledText type={"Caption"}>{t('weAreFetchingYourData')}</StyledText>
                                </div>
                            )}
                        </>
                    )}

                    <div>
                        <FlexRow justify={"center"} style={{height: '100%'}}>
                            <Grid gridTemplateColumns={"repeat(2, 1fr)"} columnGap={40} rowGap={40}>
                                {getStats().map(x => <React.Fragment key={x.index}>{x.element}</React.Fragment>)}
                            </Grid>
                        </FlexRow>
                    </div>
                </Grid>

                <Separator />

                <Grid gridTemplateColumns={"2fr 1fr 1fr 1fr"}>
                    <Gap direction={"vertical"} size={"small"}>
                        <StyledText type={"Body"} weight={"Bold"} color={"secondary"}>{t('emissions')}</StyledText>
                        <StyledText type={"Caption"}>{t('emissionExplainer')}</StyledText>
                    </Gap>
                    <FlexRow justify={"start"} align={"center"} style={{gap: 8}} direction={"column"}>
                        <FlexRow  justify={"center"} align={"center"}>
                            <LightningIcon />
                        </FlexRow>
                        <div style={{maxWidth: 150}}>
                            <StyledText type={"Lead"} weight={"Bold"}>{energiData?.c02EmissionDK1 ?? 0} g</StyledText><StyledText type={"Small"}> / kWh</StyledText>
                        </div>
                        <StyledText style={{textAlign: 'center', maxWidth: 150}} type={"Small"}>{t('emission.stat.1')}</StyledText>
                    </FlexRow>

                    <FlexRow justify={"start"} align={"center"} style={{gap: 8}} direction={"column"}>
                        <FlexRow  justify={"center"} align={"center"}>
                            <LightningIcon />
                        </FlexRow>
                        <div style={{maxWidth: 150}}>
                            <StyledText type={"Lead"} weight={"Bold"}>{energiData?.c02EmissionDK2 ?? 0} g</StyledText><StyledText type={"Small"}> / kWh</StyledText>
                        </div>
                        <StyledText style={{textAlign: 'center', maxWidth: 150}} type={"Small"}>{t('emission.stat.2')}</StyledText>
                    </FlexRow>

                    <FlexRow justify={"start"} align={"center"} style={{gap: 8}} direction={"column"}>
                        <FlexRow  justify={"center"} align={"center"}>
                            <LightningIcon />
                            <Tooltip text={t('emission.stat.tooltip.3')} />
                        </FlexRow>
                        <div>
                            <StyledText type={"Lead"} weight={"Bold"}>{energiData?.c02EmissionMarket ?? 0} g</StyledText><StyledText type={"Small"}> / kWh</StyledText>
                        </div>
                        <StyledText style={{textAlign: 'center', maxWidth: 150}} type={"Small"}>{t('emission.stat.3')}</StyledText>
                    </FlexRow>
                </Grid>
            </Box>

            <CalendlyBooker />
            </>
    )
}

export default AgreementDashboardPage